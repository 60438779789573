/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { Component } from 'react';
import Layout from "../components/layout"
import "../css/sanctionPage.css"
import searchIcon from "../images/search-solid.svg"


class SanctionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchＮame: '',
      searchScore: 60,
      sectionList: [],
      tipMsg: '',
      isSearching: false,
      searchSuccess: false,
      searchMsg: '',
    };
  }

  onSubmitSearch = () => {
    if(this.state.searchＮame === ''){
      this.setState({
        tipMsg: '請輸入搜尋關鍵字',
      })
      return
    }else{
      if(this.state.searchScore === ''){
        this.setState({
          searchScore: 60,
          tipMsg: 'Score default is 60',
        })
      }
      this.setState({tipMsg: '',})
    }
    let requestData = {};
    requestData.name = this.state.searchＮame;
    requestData.minScore = this.state.searchScore;
    
    var request = new XMLHttpRequest();
      this.setState({ isSearching: true, searchSuccess: false, sectionList: [], searchMsg: '' })
      request.open('POST', 'https://www.ediscovery.com.tw:8080/search');
      request.setRequestHeader('Content-Type', 'application/json');
      request.onload = function() {
          if (request.status === 200) {
            const result = JSON.parse(request.responseText);
            this.setState({ isSearching: false , searchSuccess: true})
            this.onResult(result)
          }
          else if (request.status !== 200) {
              alert(request.status);
          }
      }.bind(this);
      request.send(JSON.stringify(requestData))
  }
  onResult = (result) => {
    if(result.length === 0){
      this.setState({ searchMsg: '此搜尋無結果' })
    }
    this.setState({
      sectionList: result
    })
  }

  render() {
    return (
      <Layout>
        <div className="sanction">
          <div className="header-wrap">
            <div className="search-wrap">
              <div className="title">
                禁運名單
              </div>
              <div className="search section">
                <div className="search-item">
                  <input type="text" 
                          placeholder="Enter Name"
                          value={this.state.searchＮame}
                          onChange={(event) => this.setState({searchＮame: event.target.value, searchSuccess: false, searchMsg: ''})}
                  >
                  </input>
                </div>
                <div className="search-item score">
                  <input type="number" 
                          placeholder="Enter Score"
                          value={this.state.searchScore}
                          min='0' max='100'
                          onChange={(event) => this.setState({searchScore: event.target.value, searchSuccess: false, searchMsg: ''})}
                  >
                  </input>
                </div>
                <button onClick={() => this.onSubmitSearch()}>
                  <img src={searchIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="sanction-table">
            <div className="section sanction-table-wrap">
              {
                (this.state.tipMsg !== '')
                &&
                <div className="errmsg">{this.state.tipMsg}</div>
              }
              {
                this.state.isSearching
                &&
                <div className="load-wrapp">
                  <div className="load">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                  <div>搜尋中，請稍候...</div>
                </div>
              }
              {
                this.state.searchSuccess
                &&
                <div className="searchSuccess">搜尋完畢，請查看結果</div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">來源 / source</th>
                    <th scope="col">國家 / country</th>
                    <th scope="col">名稱 / name</th>
                    <th scope="col">別名 / aka names</th>
                    <th scope="col">分數 / max score</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.sectionList.map(item =>
                      <tr key={`${item.uid}`}>
                        <td rwd-title="來源">{item.source}</td>
                        <td rwd-title="國家">{item.country}</td>
                        <td rwd-title="名稱">{item.name}</td>
                        <td rwd-title="別名">
                          <div className="akaNames-group">
                            {
                              item.akaNames !== undefined
                              &&
                              item.akaNames.map(akaNames => 
                                (<span className="akaNames" key={`${akaNames}`}>{akaNames}</span>)
                              )
                            }
                          </div>
                        </td>
                        <td rwd-title="分數">{item.maxScore}</td>
                      </tr>
                      )
                  }
                </tbody>
              </table>
              { this.state.searchMsg !== '' &&  <div className="searchMsg">{this.state.searchMsg}</div>}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SanctionPage;